import { createStore } from 'vuex'
import authentication from './modules/authentication'
import storeinternal from './modules/storeinternal'
import configuration from './modules/configuration'
import billing from './modules/billing'
import error from './modules/error'

const store = createStore({
    modules: {
        authentication,
        storeinternal,
		configuration,
        billing,
        error
    },
})

export default store