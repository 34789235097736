import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import './assets/styles/layout.scss';
import './assets/demo/flags/flags.css';
import BootstrapVueNext from 'bootstrap-vue-next'

import { createApp, reactive } from 'vue';
import router from './router';
 
 import AppWrapper from './AppWrapper.vue';
 import PrimeVue from 'primevue/config';
 import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import Dropdown from 'primevue/dropdown';
import Toolbar from 'primevue/toolbar';
import vue3GoogleLogin from 'vue3-google-login';
import store from "./store";
import Toast from 'vue3-toast-single'
import 'vue3-toast-single/dist/toast.css'

import Ripple from 'primevue/ripple';
import Tooltip from 'primevue/tooltip';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';

import StyleClass from 'primevue/styleclass';


import Avatar from 'primevue/avatar';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import Textarea from 'primevue/textarea';
import Card from 'primevue/card';
import Button from 'primevue/button';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import { googleAuthenticationClientId } from '../config/config';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

router.beforeEach(function(to, from, next) {
    window.scrollTo(0, 0);
    next();
});


const app = createApp(AppWrapper);

app.config.globalProperties.$appState = reactive({ theme: 'lara-light-indigo', darkTheme: false });

app.use(PrimeVue, { ripple: true, inputStyle: 'outlined' });
app.use(ConfirmationService);
app.use(ToastService);
app.use(router);
app.use(store);
app.use(BootstrapVueNext);
app.use(Toast, { verticalPosition: "top", horizontalPosition: "right", transition: 'slide-right', duration: 3000 });

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);

app.directive('badge', BadgeDirective);
app.directive('styleclass', StyleClass);


app.component('Badge', Badge);
app.component('Toolbar', Toolbar);
app.component('Dropdown', Dropdown);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('Card', Card);
app.component('Avatar', Avatar);
app.component('Textarea', Textarea);
app.component('Button', Button);
app.component('Column', Column);
app.component('InputText', InputText);
 
// // Make BootstrapVue available throughout your project
//Vue.use(BootstrapVue)
// // Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)

//google
app.use(vue3GoogleLogin, {
  clientId: googleAuthenticationClientId,
  scope: "profile email"
  //prompt: "select_account"
})

app.mount('#app');