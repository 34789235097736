<template>
	<div class="layout-topbar">
		<router-link to="/" class="layout-topbar-logo" style="text-decoration: none;">
			<span>CPC Admin Panel</span>
		</router-link>
		<button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
			<i class="pi pi-bars"></i>
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
            <li  v-if="selectedStore" >
            	<Dropdown v-model="selectedStore" :options="allStores" optionLabel="shop" placeholder="Select Store" :filter="true" />
            </li>
			<li>
				<Button class="p-link layout-topbar-button cursor p-button-outlined p-button-success mr-2 mb-2">
					<i class="pi pi-user"></i>
					 {{userData.logoname }}
				</Button>
			</li>
            <li>
                <Button icon="pi pi-sign-out" class="p-button-rounded p-button-danger mr-2 mb-2" @click="logout" />
			</li>
		</ul>
	</div>
</template>

<script>
import apiStore from "./store/index"
export default {
    async mounted() {
		await this.fetchUserData();
	},
    data() {
        return {
			userData: {},
			selectedStore: {}
        }
    },
	watch: {
		'selectedStore': function(newVal, oldValue) {
			apiStore.commit('storeinternal/setSelectedStore', newVal)
		},
		'selectedStoreFromStore': function(newValue, old) {
			if(old === null && newValue !== null)
				this.getCurrentSelectedStore();
		}
	},
	methods: {
		async logout() {
			await apiStore.dispatch("authentication/logout", {})
        },
		toggleMenuevent(event) {
				console.log("e:",event);
			},
		toggleMenu(event) {
				this.$refs.menu.toggle(event);
			},
		onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		async fetchUserData () {
			var response = await apiStore.getters['authentication/getuserData']
			this.userData = response;
		},
		async getCurrentSelectedStore() {
			this.selectedStore = await apiStore.getters['storeinternal/getSelectedStore'];
		}
    },
	computed: {
		allStores() {
			return apiStore.getters['storeinternal/getAllStores'];
		},
		selectedStoreFromStore() {
			return apiStore.getters['storeinternal/getSelectedStore'];
		}
	}
}
</script>
<style >
.cursor{
cursor:pointer;
}
.layout-topbar .layout-topbar-button{
width:auto!important;
padding: 0 15px;
border-radius: 5%;
}
</style>