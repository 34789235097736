import axios from 'axios'
import store from '../store'
import { apiUrl } from './../../config/config'

export const BASE_URL = apiUrl

function getGoogleAuthTokenHeader() {
    if(localStorage.getItem('calcuserData')) {
        var localstoragedata = JSON.parse(localStorage.getItem('calcuserData'));
        return { 'Authorization': 'Bearer ' + localstoragedata.token }
    }

    return { 'Authorization': null }
}

function getAuthTokenHeader() {
    var token = store.getters['calculator/getIdentifier']
    if (typeof token === 'undefined' || token === null) {
        // if (import.meta.env.VITE_APP_MODE === 'local') {
        //     token = '9CDE1B44-82D0-7A30-E763-EE5AE81885CA'
        // } else {
        //     return null
        // }
    }
    return { apptoken: token }
}

export const get = async (url, params) => {
    try {
        const tokenHeader = getGoogleAuthTokenHeader()
        const response = await axios.get(BASE_URL + url, { params: params, headers: tokenHeader })
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const post = async (url, data) => {
    try {
        const tokenHeader = getGoogleAuthTokenHeader()
        const response = await axios.post(BASE_URL + url, data, { headers: tokenHeader })
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const externalGet = async (url, params) => {
    try {
        const tokenHeader = getAuthTokenHeader()
        const response = await axios.get(BASE_URL + url, { params: params, headers: tokenHeader })
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const externalPost = async (url, data) => {
    try {
        const tokenHeader = getAuthTokenHeader()
        const response = await axios.post(url, data, { headers: tokenHeader })
        return response.data
    } catch (error) {
        console.error(error)
        throw error
    }
}

export default {
    BASE_URL,
    get,
    post,
    externalGet,
    externalPost,
}
