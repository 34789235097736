import api from '../../util/api'

export default {
    namespaced: true,
    state: {
        //identifier: null
    },
    getters: {
        // getIdentifier: (state) => {
        //     return state.identifier
        // }
    },
    mutations: {
        // setIdentifier(state, payload) {
        //     state.identifier = payload
        // }
    },
    actions: {
        // async setuphideMenu(context, data) {
        //     context.commit('setuphideMenu', data.value)
        // },
        // async authenticate({ commit }, payload) {
        //     const response = await newApi.get('/api/v1/auth', { ...payload })
        //     return response
        // },
        async updateProductSyncRequiredFlag({ commit }, payload) {
            const response = await api.post('/cpcsupport:productSyncRequired?shopname=' + payload.shop, { })
            return response
        }
    },
}
