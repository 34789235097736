import api from '../../util/api'

export default {
    namespaced: true,
    state: {
        //identifier: null
    },
    getters: {
        // getIdentifier: (state) => {
        //     return state.identifier
        // }
    },
    mutations: {
        // setIdentifier(state, payload) {
        //     state.identifier = payload
        // }
    },
    actions: {
        async getCustomCharges({ commit, state }, payload) {
            const response = await api.get('/Billing/CustomCharges?installationId=' + payload.id, payload)
            return response
        },
        async addCustomCharge({ commit }, payload) {
            const response = await api.post('/Billing/AddCharge', payload)
            return response
        }
    },
}
