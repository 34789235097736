import router from '@/router'; 
import {  googleLogout } from "vue3-google-login"; 
import api from '../../util/api';

export default {
    namespaced: true,
    state:{
		isAuthenticated: false,
		calcuserData:{}
    },
    getters:{
		isAuthenticated(state) {
			return state.isAuthenticated;
		},
		getuserData(state) {
			return state.calcuserData;
		}
    },
    mutations:{
		setUserData(state,data) {
            state.calcuserData=data;
        },
		setisAuthenticated(state, data) {
            state.isAuthenticated = data;
        },
		removeUserData(state) {
            state.calcuserData={};
			state.isAuthenticated = false
			googleLogout();
			localStorage.removeItem('calcuserData');
			router.push('/login')
        }
    },
    actions:{
		logout({commit}) {
            commit('removeUserData');
        },
		async googleAuthCallback({ commit, state }, data) {
            const response = await api.post('/AuthenticationInternal/ValidateUser?code=' + data.access_token, {})
			if(response.status==1  && typeof response.userdata!=="undefined"){
				state.token = response.token;
				data.toast.add({severity:'success', summary: 'Calculator Admin Login', detail:'Successfully Login', life: 3000});
			   var userData= {
					 logoname: response.userdata.username.charAt(0).toUpperCase() + response.userdata.username.slice(1),
					 id: response.userdata.id,
					 name: response.userdata.username,
					 email: response.userdata.email,
					 default_calc_installation: response.userdata.default_calc_installation,
					 shopdata:[],
					 token:response.token
				 };
				localStorage.setItem('calcuserData', JSON.stringify(userData));
				commit('setUserData', userData);
				commit('setisAuthenticated', true);
				router.push({ name: 'dashboard' })
		   } else {
			   data.toast.add({severity:'error', summary: 'Calculator Admin Login', detail:response.message, life: 3000});
		   }
        }
    }
}