import store from '../store/index'
class AuthenticationService {
    isAuthorized() {
        var isAuthenticated = store.getters['authentication/isAuthenticated']
        if(!isAuthenticated) {
            //check of the local storage has token
            if(localStorage.getItem('calcuserData')) {
                var userDataFromLocalStorage = JSON.parse(localStorage.getItem('calcuserData'));
                store.commit('authentication/setUserData', userDataFromLocalStorage)
                store.commit('authentication/setisAuthenticated', true)
                return this.isAuthorized()
            }
        }
        return isAuthenticated
    }
}
export const AuthenticationServiceClient = new AuthenticationService()
