import api from '../../util/api';
export default {
    namespaced: true,
    state: {
        allStores: [],
        selectedStore: null
    },
    getters: {
        getAllStores(state) {
			return state.allStores
		},
		getSelectedStore(state) {
			return state.selectedStore;
		}
    },
    mutations: {
        setSelectedStore(state, data) {
            state.selectedStore = data;
        },
        setAllstores(state, data) {
			state.allStores = data;
		}
    },
    actions: {
		async getAllStores({ commit, state }, data) {
            const response = await api.get('/StoreInternal/all', {})
			commit('setAllstores', response);
			return response;
        },
        async getFasterCheckoutConfiguration({ commit, state }, data) {
            const response = await api.get('/StoreInternal:fasterCheckoutConfig?installationId=' + data.id, {})
			return response;
        },
        async saveFasterCheckoutConfiguration({ commit, state }, data) {
            const response = await api.post('/StoreInternal:updateFasterCheckoutConfig', data)
			return response;
        }
    },
}