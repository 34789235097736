import { createWebHistory,createRouter } from 'vue-router';
import { AuthenticationServiceClient } from './services/auth';
import App from './App.vue';

const routes = [ 
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: () => import('./components/Dashboard.vue')
            },
            {
                path: '/charge/custom',
                name: 'custom-charge',
                component: () => import('./components/CustomCharge.vue')
            },
            {
                path: '/app/custom-price-calculator',
                name: 'cpc-app-embeded',
                component: () => import('./components/ShopifyAppEmbedded.vue')
            },
            {
                path: '/store/configuration',
                name: 'cpc-configuration',
                component: () => import('./components/Configuration.vue')
            },
            {
                path: '/store/errorlogs',
                name: 'cpc-error-logs',
                component: () => import('./components/ErrorLogs.vue')
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./components/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;

router.beforeEach((to, _from, next) => {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);

    if (authRequired){
        if(!AuthenticationServiceClient.isAuthorized()) {
            return next('/login');	
        } else {
            next();
        }
    }
    else{
         next();
    }
});


