import api from '../../util/api'

export default {
    namespaced: true,
    state: {
    },
    getters: {

    },
    mutations: {

    },
    actions: {
        async getStoreErrorLogs({ commit, state }, payload) {
            const response = await api.get('/Logs/Errors?installationId=' + payload.id, {})
            return response
        }
    },
}
